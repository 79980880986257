import { supabase } from '../client'

export async function getHighestBidForAuction(auctionId: number) {
  //I'm open to changes to this logic, this seems to be performant according to: https://stackoverflow.com/a/13722123/15072566
  //What this doesn't handle is multiple bids with the same amount - then we would only get one (we should prevent that anyway)
  const { data, error } = await supabase
    .from('bids')
    .select('*')
    .match({ auctionId: auctionId })
    .order('priceInEuro', { ascending: false, nullsFirst: false })
    .limit(1)
    .single()
  return { data, error }
}
