import type { AnalyticsEvent } from '../../events'
import { apiClient } from '@twlvxtwlv/api'
import type { TrackEventType } from '../../api-types'

//Future improvement: change the parameters to be an inferred type from zod without Omit - for this we need to fix the type of userProperties in the zod schema
export function serverSideTrackEvent(input: Omit<TrackEventType, 'event'>, event: AnalyticsEvent) {
  return apiClient.post('/api/stats/event', {
    ...input,
    event,
  })
}
