import { toAuctionWithBids } from '../../../../mapping'
import type { Auction, AuctionWithBids, Bid } from '../../types'
import { getBidsByAuctionIdPage } from '../bids'

export async function getAuctionWithBids(auction: Auction): Promise<AuctionWithBids | null> {
  let auctionWithBids: AuctionWithBids | null = null

  if (auction) {
    const bidsResult = await getBidsByAuctionIdPage(auction.id)
    if (!bidsResult.error && bidsResult.page) {
      let bids: Bid[] = []
      let hasMoreBids = false
      if (bidsResult.page.bids.length > 0) {
        bids = bidsResult.page.bids
        hasMoreBids = bidsResult.page.hasMoreBids
      }
      auctionWithBids = toAuctionWithBids(auction, bids, hasMoreBids)
    }
  }

  return auctionWithBids
}
