import { gql } from 'graphql-request'

gql`
  fragment NewArtist on Query {
    newArtist(locale: $locale, publicationState: $publicationState) {
      data {
        attributes {
          Artist {
            Name
            Image {
              data {
                attributes {
                  url
                  alternativeText
                }
              }
            }
          }
        }
      }
    }
  }
`
