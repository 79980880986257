import { supabase } from '../client'
import type { ArtistInsert } from '../../types'

export async function addArtist(artistInsert: ArtistInsert) {
  const { data, error, status } = await supabase
    .from('artists')
    .insert(artistInsert)
    .select()
    .single()
    .throwOnError()

  return { data, error, status }
}
