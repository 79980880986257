import { supabase } from '../client'
import type { TfProductDiscordRoleInsert } from '../../types'

export async function addDiscordRole(discordRoleInsert: TfProductDiscordRoleInsert) {
  const { data, error, status } = await supabase
    .from('tfProductDiscordRole')
    .insert(discordRoleInsert)
    .select()
    .single()
  return { data, error, status }
}
