import { supabase } from '../client'
import type { DiscordGuilds } from '../../types'
import type { PostgrestError } from '@supabase/supabase-js'

export async function getDiscordGuildById(guildId: string): Promise<{
  data: Awaited<DiscordGuilds> | null
  error: PostgrestError | null
}> {
  const { data, error } = await supabase
    .from('discordGuilds')
    .select('*')
    .eq('guildId', guildId)
    .single()

  return { data, error }
}
