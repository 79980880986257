import { supabase } from '../client'

export async function deleteArtistFromTrack(trackId: string, artistId: string) {
  const { data, error } = await supabase
    .from('trackArtists')
    .delete()
    .match({
      artistId,
      trackId,
    })
    .throwOnError()
  return { data, error }
}
