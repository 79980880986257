import { getProductPreviewByProductId } from './get-product-preview-by-product-id'

const cmsUrl = process.env.NEXT_PUBLIC_CMS_API_URL

export async function getPreviewUrlByProductId(productId: string) {
  const productPreview = await getProductPreviewByProductId(productId)

  const filePath = productPreview?.data?.cmsVideoUrl
  if (!filePath || !cmsUrl) return null

  return `${cmsUrl}${filePath}`
}
