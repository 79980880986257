import { gql } from 'graphql-request'
import cmsGraphQlClient from '../client/graphql-client'

gql`
  query getHottestDropsWithHottestOfDrops($publicationState: PublicationState) {
    ...HottestDrop
  }
`

export function getHottestDrops() {
  return cmsGraphQlClient.getHottestDropsWithHottestOfDrops()
}
