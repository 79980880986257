import type { UseInfiniteQueryOptions } from '@tanstack/react-query'
import { useInfiniteQuery } from '@tanstack/react-query'
import type { GetProductsByPageQuery, GetProductsByPageQueryVariables } from '@twlvxtwlv/tokenforge'
import { getProductsByPage, toProductsFromPages } from '@twlvxtwlv/tokenforge'

const defaultProductStartPage = 1

function fetchProductsByPage(
  { pageParam = defaultProductStartPage },
  { itemsPerPage, locale, creatorUuid, taxonCode }: GetProductsByPageQueryVariables
) {
  const page = pageParam ?? defaultProductStartPage
  return getProductsByPage({
    page,
    itemsPerPage,
    locale,
    creatorUuid,
    taxonCode,
  })
}

export function getUseProductsOptions(variables: GetProductsByPageQueryVariables) {
  const options: UseInfiniteQueryOptions<GetProductsByPageQuery> = {
    queryKey: [
      'products',
      {
        selectedArtistId: variables.creatorUuid,
        selectedGenreId: variables.taxonCode,
        locale: variables.locale,
      },
    ],
    queryFn: (pageParams) => fetchProductsByPage(pageParams, variables),
    getNextPageParam: (lastPage, allPages) => {
      const lastPageInCollection = lastPage?.products?.paginationInfo.lastPage ?? 1
      return lastPageInCollection > allPages.length ? allPages.length + 1 : undefined
    },
  }

  return options
}

export function useProducts(variables: GetProductsByPageQueryVariables) {
  const res = useInfiniteQuery(getUseProductsOptions(variables))

  const products = toProductsFromPages(res?.data?.pages)

  return { ...res, products }
}
