import type { Artist, PlaylistWithTracks, TrackWithArtists } from '../../types'

export function toPlaylistWithTracks(data: PlaylistWithTracks | null) {
  if (data?.tracks && data.tracks.length > 0) {
    data.tracks = data.tracks.filter(Boolean).flatMap((t) => {
      const track = (t as any).track as TrackWithArtists
      track.artists = track.artists?.filter(Boolean).flatMap((a) => (a as any).artist as Artist)

      return track
    })
  }

  if (data?.artists && data.artists.length > 0) {
    data.artists = data.artists.filter(Boolean).flatMap((a) => (a as any).artist as Artist)
  }

  return data
}
