import { supabase } from '../client'

export async function getPreviewEncodingById(previewEncodingId: string) {
  const { data, error } = await supabase
    .from('previewEncodings')
    .select(`*`)
    .match({ id: previewEncodingId })
    .single()

  return { data, error }
}
