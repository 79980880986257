import type { Asset, DiscordDeepLinkVisibilityType } from '@twlvxtwlv/types'
import type { ComponentNftContentLinks } from '../generated/graphql'

export function nftLinksToAssets(
  cmsNftLinks: ComponentNftContentLinks[],
  discordDeepLinkVisibility: DiscordDeepLinkVisibilityType,
  isProductOwnedByCustomer: boolean
): Asset[] {
  if (!cmsNftLinks) {
    return []
  }

  const assets = cmsNftLinks.map((cmsNftLink) => {
    if (cmsNftLink.type === 'Discord') {
      return null
    }
    const isLocked =
      cmsNftLink.type === 'Metaverse'
        ? !isProductOwnedByCustomer
        : discordDeepLinkVisibility === 'locked'
    const asset: Asset = {
      type: cmsNftLink.type ?? 'Media',
      completeLink: cmsNftLink.url ?? '',
      locked: isLocked,
      downloadLink: cmsNftLink.url ?? '',
      fileName: cmsNftLink.description ?? '',
      private: true,
      originalFileName: cmsNftLink.description ?? '',
      uuid: cmsNftLink.description ?? '',
    }
    return asset
  })
  return assets.filter((asset) => asset !== null) as Asset[]
}
