import { useInfiniteQuery } from '@tanstack/react-query'
import type { GetTransactionsByProductIdQueryVariables } from '@twlvxtwlv/tokenforge'
import { getTransactionsByProductId } from '@twlvxtwlv/tokenforge'

const defaultTransactionsStartPage = 1
const defaultTransactionsPerPage = 10

function fetchTransactionsPerPage(
  { pageParam = defaultTransactionsStartPage }: { pageParam?: number },
  { itemsPerPage, productId }: GetTransactionsByProductIdQueryVariables
) {
  const page = pageParam ?? defaultTransactionsStartPage
  return getTransactionsByProductId({
    page,
    itemsPerPage: itemsPerPage ?? defaultTransactionsPerPage,
    productId,
  })
}

export function useTransactions({
  page = 1,
  itemsPerPage = 10,
  productId,
}: GetTransactionsByProductIdQueryVariables) {
  const result = useInfiniteQuery({
    queryKey: ['transactions', { productId: productId }],
    queryFn: ({ pageParam = defaultTransactionsStartPage }) =>
      fetchTransactionsPerPage(
        { pageParam },
        {
          page,
          itemsPerPage,
          productId,
        }
      ),
    getNextPageParam: (lastPage, allPages) => {
      const lastPageInCollection = lastPage?.transactions?.paginationInfo.lastPage ?? 1
      return lastPageInCollection > allPages.length ? allPages.length + 1 : undefined
    },
  })

  const totalCount = result?.data?.pages?.[0]?.transactions?.paginationInfo.totalCount

  const totalNumberOfTransactions = totalCount ? totalCount - 1 : 0

  const transactions = result?.data?.pages?.flatMap((page) => page?.transactions?.collection ?? [])

  return {
    ...result,
    totalNumberOfTransactions,
    transactions,
  }
}
