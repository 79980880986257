import cmsGraphQlClient from '../client/graphql-client'
import gql from 'graphql-tag'
import type { GetNftByIdQueryVariables } from '../generated/graphql'

gql`
  query getNftById($id: String, $locale: I18NLocaleCode) {
    nfts(
      publicationState: PREVIEW
      locale: $locale
      filters: { tokenForge: { nftId: { eq: $id } } }
    ) {
      meta {
        pagination {
          total
        }
      }

      data {
        id
        attributes {
          tokenForge {
            id
            nftId
          }
          tokenType {
            id
            headline
            subHeadline
            description
          }
          background {
            data {
              id
              attributes {
                name
                alternativeText
                mime
                url
                previewUrl
              }
            }
          }
          nftContent {
            links {
              id
              description
              type
              url
            }
          }
        }
      }
    }
  }
`

export function getNftById(variables: GetNftByIdQueryVariables) {
  return cmsGraphQlClient.getNftById(variables)
}
