import { supabase } from '../client'

export async function getAuctionById(auctionId: number) {
  const { data, error } = await supabase
    .from('auctions')
    .select('*')
    .match({ id: auctionId })
    .limit(1)
    .single()
  return { data, error }
}
