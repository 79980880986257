import { supabase } from '../client'
import type { PreviewEncodingInsert } from '../../types'

export async function addPreviewEncoding(previewEncodingInsert: PreviewEncodingInsert) {
  const { data, error, status } = await supabase
    .from('previewEncodings')
    .insert(previewEncodingInsert)
    .select()
    .single()

  return { data, error, status }
}
