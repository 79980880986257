import type { TfCustomerDiscordUser } from '@twlvxtwlv/supabase'
import { getPairedDiscordUser } from '@twlvxtwlv/supabase'
import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

type UseDiscordOptions = UseQueryOptions<{
  data: TfCustomerDiscordUser | null
  error: unknown | null
}>

interface UseDiscordUserVariables {
  customerId: string | null
}

export function getUseDiscordUserOptions({
  customerId,
}: UseDiscordUserVariables): UseDiscordOptions {
  return {
    queryKey: ['discordUser', { id: customerId }],
    enabled: !!customerId,
    queryFn: () => getPairedDiscordUser(customerId!),
  }
}

export function useDiscordUser({ customerId }: UseDiscordUserVariables) {
  const res = useQuery(getUseDiscordUserOptions({ customerId }))
  const discordUserId = res?.data?.data?.discordUserId ?? null

  return {
    discordUserId,
    ...res,
  }
}
