import cmsGraphQlClient from '../client/graphql-client'
import gql from 'graphql-tag'
import type { GetNftsByIdsQueryVariables } from '../generated/graphql'

gql`
  query getNftsByIds($ids: [String], $locale: I18NLocaleCode) {
    nfts(
      publicationState: PREVIEW
      locale: $locale
      filters: { tokenForge: { nftId: { in: $ids } } }
    ) {
      meta {
        pagination {
          total
        }
      }

      data {
        id
        attributes {
          tokenForge {
            id
            nftId
          }
          nftContent {
            links {
              id
              description
              type
              url
            }
          }
        }
      }
    }
  }
`

export function getNftsByIds(variables: GetNftsByIdsQueryVariables) {
  return cmsGraphQlClient.getNftsByIds(variables)
}
