import { useMemo } from 'react'
import { type OpenGraphProperties } from '@twlvxtwlv/types'

type OGImage = {
  alt: string
  type: string
  url: string
  width?: string
  height?: string
} | null

type PageOgData = Omit<OpenGraphProperties, 'image' | 'card' | 'site_name'> & {
  card?: OpenGraphProperties['card']
  image: OGImage
}

export const useOpenGraph = (data: PageOgData & { locale: OpenGraphProperties['locale'] }) => {
  const ogProperties = useMemo<OpenGraphProperties>(() => {
    return {
      locale: data.locale,
      url: data.url,
      title: data.title,
      type: data.type,
      author: data.author,
      site_name: '',
      description: data.description,
      image: data.image
        ? {
            type: data.image.type,
            url: absUrl(data.image.url),
            alt: data.image.alt || '',
            height: data.image.height || '630',
            width: data.image.width || '1200',
          }
        : null,
      card: data.card || data.image ? 'summary_large_image' : 'summary',
      section: data.section,
      modified_time: data.modified_time,
      published_time: data.published_time,
    }
  }, [data])

  return ogProperties
}

export const absUrl = (path: string): string => {
  path = path.trim()
  if (path.startsWith('http')) {
    return path
  }
  if (path.indexOf('/') === 0) {
    path = path.substring(1)
  }

  const appUrl = process.env.APP_URL || process.env.NEXT_PUBLIC_APP_URL || 'http://localhost:3000'

  return `${appUrl}/${path}`
}
