import type { GetNftByIdQueryVariables } from '@twlvxtwlv/cms'
import { getNftById } from '@twlvxtwlv/cms'
import { useQuery } from '@tanstack/react-query'

export function getUseNftOptions(variables: GetNftByIdQueryVariables) {
  return {
    queryKey: ['detailCmsProduct', { productId: variables.id ?? null, locale: variables.locale }],
    queryFn: () => getNftById(variables),
    enabled: !!variables.id,
  }
}

export function _useNft(variables: GetNftByIdQueryVariables) {
  return useQuery(getUseNftOptions(variables))
}
