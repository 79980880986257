import { useQuery } from '@tanstack/react-query'
import { getCountries } from '@twlvxtwlv/tokenforge'

export function getUseCountriesOptions() {
  return {
    queryKey: ['countries'],
    queryFn: () => getCountries({}),
  }
}

export function useCountries() {
  const res = useQuery(getUseCountriesOptions())
  const countries = res?.data?.countries?.collection

  return { countries, ...res }
}
