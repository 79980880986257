import { supabase } from '../client'
import type { AdminUpdate } from '../../types'

export async function updateAdmin(tfCustomerIri: string, adminUpdate: AdminUpdate) {
  const { data, error } = await supabase
    .from('admins')
    .update(adminUpdate)
    .match({ tfCustomerIri })
    .select()
    .single()
    .throwOnError()

  return { data, error }
}
