import { supabase } from '../client'

export async function deletePlaylist(playlistId: string) {
  const { data, error } = await supabase
    .from('playlists')
    .delete()
    .match({ id: playlistId })
    .throwOnError()
  return { data, error }
}
