import type { PlaylistWithTracks } from '../../types'
import { supabase } from '../client'
import { toPlaylistWithTracks } from './to-playlist-with-tracks'

const selectQueryWithStreams = `
*,
artists:playlistArtists(artist:artists(*)),
tracks:playlistTracks(track:tracks(*, encodings(previewEncoding(*)), artists:trackArtists(artist:artists(*))))`

const selectQueryWithPreviews = `
*,
artists:playlistArtists(artist:artists(*)),
tracks:playlistTracks(track:tracks(id,name, imageUrl, encodings(previewEncoding(*)),artists:trackArtists(artist:artists(*))))\``

export type UrlType = 'preview' | 'stream'

export async function getPlaylistByTfProductId(tfProductId: string, urlType: UrlType) {
  const { data, error } = await supabase
    .from('playlists')
    .select(urlType === 'stream' ? selectQueryWithStreams : selectQueryWithPreviews)
    .match({ tfProductId })
    .returns<PlaylistWithTracks[]>()
    .limit(1)
    .single()

  const mappedData = toPlaylistWithTracks(data)

  return { data: mappedData, error }
}
