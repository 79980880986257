import { supabase } from '../client'
import type { EncodingUpdate } from '../../types'

export async function updateEncoding(encodingId: string, encodingUpdate: EncodingUpdate) {
  const { data, error } = await supabase
    .from('encodings')
    .update(encodingUpdate)
    .match({ id: encodingId })
    .select()
    .single()

  return { data, error }
}
