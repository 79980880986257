import { supabase } from '../client'

export async function deleteTrackFromPlaylist(playlistId: string, trackId: string) {
  const { data, error } = await supabase
    .from('playlistTracks')
    .delete()
    .match({
      playlistId,
      trackId,
    })
    .throwOnError()
  return { data, error }
}
