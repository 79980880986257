import type { AuctionState } from '../../types'

export function calculateAuctionState(
  startedAtString: string | undefined,
  finishedAtString: string | undefined
): AuctionState {
  if (!startedAtString || !finishedAtString) {
    return 'ready'
  }
  const startedAt = new Date(startedAtString).getTime()
  const finishedAt = new Date(finishedAtString).getTime()
  const now = new Date(new Date().toUTCString()).getTime()
  if (now < startedAt) {
    return 'ready'
  } else if (now >= finishedAt) {
    return 'finished'
  } else {
    return 'active'
  }
}
