import type { GetTaxaByPageQuery, GetTaxaByPageQueryVariables } from '@twlvxtwlv/tokenforge'
import { getTaxaByPage, toTaxaFromPages } from '@twlvxtwlv/tokenforge'
import type { UseInfiniteQueryOptions } from '@tanstack/react-query'
import { useInfiniteQuery } from '@tanstack/react-query'

const defaultTaxaStartPage = 1

function fetchTaxaByPage(
  { pageParam = defaultTaxaStartPage },
  { itemsPerPage }: GetTaxaByPageQueryVariables
) {
  const page = pageParam ?? defaultTaxaStartPage
  return getTaxaByPage({
    page,
    itemsPerPage: itemsPerPage,
  })
}

export function getUseTaxaOptions(variables: GetTaxaByPageQueryVariables) {
  const options: UseInfiniteQueryOptions<GetTaxaByPageQuery> = {
    queryKey: ['taxa'],
    getNextPageParam: (lastPage, allPages) => {
      const lastPageInCollection = lastPage.taxa?.paginationInfo?.lastPage ?? 1
      return lastPageInCollection > allPages.length ? allPages.length + 1 : undefined
    },
    queryFn: (pageParam) => fetchTaxaByPage(pageParam, variables),
  }

  return options
}

export function useTaxa(variables: GetTaxaByPageQueryVariables) {
  const res = useInfiniteQuery(getUseTaxaOptions(variables))

  const taxa = toTaxaFromPages(res?.data?.pages)

  return { ...res, taxa }
}
