import type { PlaylistWithTracks } from '@twlvxtwlv/supabase'
import { getPlaylistByTfProductId } from '@twlvxtwlv/supabase'
import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

interface GetPlaylistOptionsVariables {
  productId: string
  isIncludingStreamUrls: boolean
}

type UsePlaylistOptions = UseQueryOptions<{
  data: PlaylistWithTracks | null
  error: unknown | null
}>

export function getUsePlaylistOptions({
  productId,
  isIncludingStreamUrls,
}: GetPlaylistOptionsVariables): UsePlaylistOptions {
  return {
    queryKey: ['playlists', { productId, isIncludingStreamUrls }],
    queryFn: () =>
      getPlaylistByTfProductId(productId, isIncludingStreamUrls ? 'stream' : 'preview'),
  }
}

export function usePlaylist(variables: GetPlaylistOptionsVariables) {
  const result = useQuery(getUsePlaylistOptions(variables))

  const playlistWithTracks = result.data?.data ?? undefined
  return {
    playlistWithTracks,
    ...result,
  }
}
