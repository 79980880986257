import { getAuctionByTfProductCodeWithBids } from '@twlvxtwlv/supabase'
import { useQuery } from '@tanstack/react-query'

export function getUseAuctionsOptions(productId: string) {
  return {
    queryKey: ['auctions', { productId }],
    queryFn: () => getAuctionByTfProductCodeWithBids(productId),
  }
}

export function useAuctions(productId: string) {
  return useQuery(getUseAuctionsOptions(productId))
}
