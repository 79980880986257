import type { GetArtistsByPageQuery, GetArtistsByPageQueryVariables } from '@twlvxtwlv/tokenforge'
import { getArtistsByPage, toArtistsByPages } from '@twlvxtwlv/tokenforge'
import type { UseInfiniteQueryOptions } from '@tanstack/react-query'
import { useInfiniteQuery } from '@tanstack/react-query'

const defaultArtistsStartPage = 1

function fetchArtistsByPage(
  { pageParam = defaultArtistsStartPage },
  variables: GetArtistsByPageQueryVariables
) {
  const page = pageParam ?? defaultArtistsStartPage
  return getArtistsByPage({
    page,
    itemsPerPage: variables.itemsPerPage,
  })
}

export function getUseArtistsOptions(variables: GetArtistsByPageQueryVariables) {
  const options: UseInfiniteQueryOptions<GetArtistsByPageQuery> = {
    queryKey: ['artists'],
    queryFn: (pageParam) => fetchArtistsByPage(pageParam, variables),
    getNextPageParam: (lastPage, allPages) => {
      const lastPageInCollection = lastPage?.customers?.paginationInfo.lastPage ?? 1
      return lastPageInCollection > allPages.length ? allPages.length + 1 : undefined
    },
  }
  return options
}

export function useArtists(variables: GetArtistsByPageQueryVariables) {
  const res = useInfiniteQuery(getUseArtistsOptions(variables))
  const artists = toArtistsByPages(res?.data?.pages)

  return { ...res, artists }
}
