import { useEffect } from 'react'
import { initDistinctId } from '@twlvxtwlv/utils/src/cookie/distinct-id/client/init-distinct-id'
import { serverSideTrackEvent } from '@twlvxtwlv/analytics/browser'
import type { AnalyticsEvent } from '@twlvxtwlv/analytics/events'

export function useServerSideTrack(event: AnalyticsEvent) {
  useEffect(() => {
    const distinctId = initDistinctId()
    if (!distinctId) return
    serverSideTrackEvent({ userId: distinctId }, event)
  }, [])
}
