import gql from 'graphql-tag'

gql`
  query getCampaigns {
    campaigns {
      data {
        id
        attributes {
          CurrentNft {
            nftId
          }
          tokenForge {
            id
            campaignId
          }
        }
      }
    }
  }
`
