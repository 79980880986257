import { useLayoutEffect } from 'react'

export const useBodyLock = () => {
  useLayoutEffect((): (() => void) => {
    const originalStyle = window.getComputedStyle(document.body).overflow
    document.body.style.overflow = 'hidden'

    return (): void => {
      document.body.style.overflow = originalStyle
    }
  }, [])
}
