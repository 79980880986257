import { supabase } from '../client'
import type { TrackWithArtists } from '../../types'
import { toTrackWithArtists } from './to-track-with-artists'

export async function getTrackById(trackId: string) {
  const { data, error } = await supabase
    .from('tracks')
    .select(`*, artists:trackArtists(artist:artists(*))`)
    .match({ id: trackId })
    .returns<TrackWithArtists[]>()
    .limit(1)
    .single()

  const mappedData = toTrackWithArtists(data)

  return { data: mappedData, error }
}
