import gql from 'graphql-tag'
import cmsGraphQlClient from '../client/graphql-client'
import type { GetClaimByIdQueryVariables } from '../generated/graphql'

gql`
  query getClaimById($id: String, $locale: I18NLocaleCode) {
    claimPages(
      publicationState: PREVIEW
      filters: { tokenForge: { pageSlug: { eq: $id } } }
      locale: $locale
    ) {
      data {
        attributes {
          tokenForge {
            nftId
          }
          image {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          description {
            description
            headline
          }
        }
      }
    }
  }
`

export function getClaimsById(variables: GetClaimByIdQueryVariables) {
  return cmsGraphQlClient.getClaimById(variables)
}
