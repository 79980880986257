import type { Auction, Bid, AuctionWithBids } from '@twlvxtwlv/supabase'

export function toAuctionWithBids(
  auction: Auction,
  bids: Bid[],
  hasMoreBids: boolean
): AuctionWithBids {
  return {
    auction,
    bids,
    hasMoreBids,
  }
}
