import { supabase } from '../client'
import type { PlaylistInsert } from '../../types'

export async function addPlaylist(playlistInsert: PlaylistInsert) {
  const { data, error, status } = await supabase
    .from('playlists')
    .insert(playlistInsert)
    .select()
    .single()
  return { data, error, status }
}
