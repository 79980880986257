import { supabase } from '../client'

export async function getAdminsPage(cursor: string = new Date().toISOString(), limit = 1000) {
  const { data, error } = await supabase
    .from('admins')
    .select('*')
    .lt('createdAt', cursor)
    .order('createdAt', { ascending: false })
    .limit(limit + 1)

  /*  let page = null
  if (!error && data !== null) {
    page = toCursorPage<Admin>(data as Admin[], limit)
  }*/

  return { data, error }
}
