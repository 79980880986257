import { useQuery } from '@tanstack/react-query'
import type { GetProductByIdQueryVariables, Product } from '@twlvxtwlv/tokenforge'
import { getProductById } from '@twlvxtwlv/tokenforge'

export function getUseProductOptions({ locale, id }: GetProductByIdQueryVariables) {
  return {
    queryKey: ['product', { id, locale }],
    queryFn: () => getProductById(id, locale ?? 'de'),
    enabled: !!id,
  }
}

export function useProduct({ locale, id }: GetProductByIdQueryVariables) {
  const result = useQuery(getUseProductOptions({ locale, id }))

  //TODO: when improving typesafety, we should get rid of the `as Product` cast
  const product = result?.data?.product as Product
  return { product, ...result }
}
