import type { ProductWithAmount } from '@twlvxtwlv/tokenforge/src/types'
import type {
  GetProductsWithFilterMyItemsQuery,
  Product,
} from '@twlvxtwlv/tokenforge/src/generated/graphql'

export function toProductsWithAmount(
  allProductPages: GetProductsWithFilterMyItemsQuery[]
): ProductWithAmount[] {
  const productsWithAmount: ProductWithAmount[] = []

  const allPages = allProductPages.flatMap((p) => p)

  const allCollections = allPages.flatMap((page) => page?.productCollections?.collection)

  if (!allCollections || allCollections.length === 0) {
    return productsWithAmount
  }

  allCollections
    .filter((pc) => !!pc?.productCollectionItems?.collection)
    .forEach((productCollection) => {
      const productCollectionItems = productCollection!.productCollectionItems!.collection
      if (!productCollectionItems) {
        return
      }

      productCollectionItems
        ?.filter((pci) => !!pci && !!pci.amount && !!pci.product?.code)
        .forEach((productCollectionItem) => {
          const product = productCollectionItem!.product
          if (!product) {
            return
          }

          const amount = productCollectionItem?.amount ?? 1
          const productVariant = productCollectionItem?.productVariant
          const productWithAmount = { ...(product as Product), amount, productVariant }
          productsWithAmount.push(productWithAmount)
        })
    })

  return productsWithAmount
}
