import { supabase } from '../client'

export async function getArtistById(artistId: string) {
  const { data, error } = await supabase
    .from('artists')
    .select(`*`)
    .match({ id: artistId })
    .single()

  return { data, error }
}
