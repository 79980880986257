import type { ComponentCampaignCampaignRoadmap } from '@twlvxtwlv/cms'
import type { Product } from '@twlvxtwlv/tokenforge'
import { getProductType } from '@twlvxtwlv/tokenforge'
import type { RoadmapEntry } from '@twlvxtwlv/types'

export const toRoadmapEntries = (
  campaignRoadmap: ComponentCampaignCampaignRoadmap[] | undefined,
  roadmapProductMap: Record<string, Product>
): RoadmapEntry[] => {
  if (!campaignRoadmap) {
    return []
  }

  const roadmapEntries = campaignRoadmap
    .filter((roadmapItem) => roadmapItem)
    .map((roadmapItem: ComponentCampaignCampaignRoadmap): RoadmapEntry => {
      let product = null
      let variant = null
      if (roadmapItem.nftId) {
        product = roadmapProductMap[roadmapItem.nftId]
        if (product) {
          variant = product?.defaultVariant
        }
      }

      return {
        nftId: roadmapItem.nftId ?? null,
        headline: roadmapItem.headline ?? null,
        subHeadline: roadmapItem.subHeadline ?? null,
        startAt: roadmapItem.startAt ?? null,
        startAtText: roadmapItem.startAtText ?? null,
        currentlyActive: false,
        currentlyAvailableTokens: variant?.onHand ?? null,
        totalAvailableTokens: variant?.totalSupply ?? null,
        priceInEuros: variant?.channelPricings?.edges?.[0]?.node?.price ?? null,
        listable: product?.listable ?? null,
        purchasable: product?.purchasable ?? null,
        overwriteBulletPointColor: 'white',
        productType: getProductType(product),
      }
    })

  const activeEntries = roadmapEntries.filter(
    (entry) => entry.startAt !== null && Date.now() >= new Date(entry.startAt).getTime()
  ) as RoadmapEntry[]

  if (activeEntries.length > 0) {
    const activeEntry = activeEntries.reduce((prev, current) =>
      prev.startAt! > current.startAt! ? prev : current
    )

    if (activeEntry) {
      activeEntry.currentlyActive = true
    }
  }

  return roadmapEntries
}
