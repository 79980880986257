import { GraphQLClient } from 'graphql-request'
import { getSdk } from '../generated/graphql'

export function cmsBaseUrl(): string {
  return `${process.env.NEXT_PUBLIC_CMS_API_URL}`
}

const cmsGraphQlClient = getSdk(new GraphQLClient(`${cmsBaseUrl()}/graphql`))

export default cmsGraphQlClient
