import { useEffect, useRef, useState } from 'react'
import { supabase } from '../db'
import type { AuctionWithBids, Bid } from '../types'

export function useLatestBids(auctionWithBids: AuctionWithBids | null | undefined) {
  const firstRenderRef = useRef(true)
  const channel = supabase.channel('highestBid')
  const [latestBid, setLatestBid] = useState<Bid | null>(null)

  useEffect(() => {
    if (!auctionWithBids?.auction?.id) {
      return
    }

    if (firstRenderRef.current && process.env.NODE_ENV === 'development') {
      firstRenderRef.current = false
      return
    }

    if (channel.state === 'joined') {
      return
    }

    const auctionId = auctionWithBids.auction.id

    // The callback function gets the "payload" object, which contains the newly inserted row
    try {
      channel
        .on(
          'postgres_changes',
          {
            event: 'INSERT',
            schema: 'public',
            table: 'bids',
            filter: `auctionId=eq.${auctionId}`,
          },
          (postgresUpdate: any) => {
            const bid = postgresUpdate.new
            setLatestBid(bid)
          }
        )
        .subscribe()
    } catch (e) {
      // Handle error and try to subscribe again?
      console.error(e)
    }

    return () => {
      supabase.removeAllChannels()
    }
  }, [])

  return { latestBid, channel }
}
