import { getAuctionsWithHighestBid } from '@twlvxtwlv/supabase'
import { useQuery } from '@tanstack/react-query'

export function getUseAuctionsWithHighestBidOptions() {
  return {
    queryKey: ['auctions'],
    queryFn: getAuctionsWithHighestBid,
  }
}

export function useAuctionsWithHighestBid() {
  const res = useQuery(getUseAuctionsWithHighestBidOptions())

  const auctionsWithHighestBid = res.data ?? []

  return { ...res, auctionsWithHighestBid }
}
