import { useEffect, useRef } from 'react'
import { trackGoal } from 'fathom-client'

export function useFathomRegistrationEvent(
  siteId: string,
  verificationValue: string | null,
  currency = 0
) {
  const firstRenderRef = useRef(true)

  useEffect(() => {
    if (firstRenderRef.current && process.env.NODE_ENV === 'development') {
      firstRenderRef.current = false
      return
    }

    if (verificationValue === 'success') {
      trackGoal(siteId, currency)
    }
  }, [])
}
