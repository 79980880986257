import type { GetSimilarProductsByGenreQueryVariables, Product } from '@twlvxtwlv/tokenforge'
import { getSimilarProductsByGenre } from '@twlvxtwlv/tokenforge'
import { useQuery } from '@tanstack/react-query'

export function getUseSimilarProductsOptions({
  genre,
  locale,
  itemsPerPage,
}: GetSimilarProductsByGenreQueryVariables) {
  return {
    queryKey: ['similarProductsByGenre', { genre, locale, itemsPerPage }],
    queryFn: () => getSimilarProductsByGenre({ genre, locale, itemsPerPage }),
  }
}

export function useSimilarProducts(variables: GetSimilarProductsByGenreQueryVariables) {
  const res = useQuery(getUseSimilarProductsOptions(variables))
  const similarProducts = (res.data?.products?.collection as Product[]) ?? []

  return { ...res, similarProducts }
}
