import type { AuctionWithHighestBid, AuctionWithHighestBidQueryResult } from '../../types'
import { supabase } from '../client'
import { toAuctionsWithHighestBid } from './to-auctions-with-highest-bids'

export const getAuctionsWithHighestBid = async (): /*productIds = [],
    limit = 15,*/
Promise<AuctionWithHighestBid[] | []> => {
  let auctionsWithHighestBid: AuctionWithHighestBid[] = []

  const { data, error } = await supabase
    .from('auctions')
    .select(`*, bids(priceInEuro)`)
    /* .in('tfProductId', productIds)*/
    .order('createdAt', {
      ascending: false,
      nullsFirst: false,
    })
    /*.limit(limit)*/
    .order('priceInEuro', { foreignTable: 'bids', ascending: false })
    .limit(1, { foreignTable: 'bids' })

  if (error) {
    return auctionsWithHighestBid
  } else {
    auctionsWithHighestBid = toAuctionsWithHighestBid(data as AuctionWithHighestBidQueryResult[])
  }

  return auctionsWithHighestBid
}
