import type { AuctionWithHighestBid } from '@twlvxtwlv/supabase'
import type { Product } from '@twlvxtwlv/tokenforge'
import type { ProductWithAuction } from './product-with-auction'

export function toProductWithAuctionList(
  products: Product[],
  auctionsWithHighestBid: AuctionWithHighestBid[] | null | undefined
): ProductWithAuction[] {
  auctionsWithHighestBid = auctionsWithHighestBid ?? []

  const auctionsWithHighestBidMap = new Map(
    auctionsWithHighestBid.map((awhb) => [awhb.tfProductId, awhb])
  )

  const productWithAuctionList: ProductWithAuction[] = []
  for (const product of products) {
    if (product) {
      const auctionWithHighestBid = auctionsWithHighestBidMap.get(product.code) ?? null
      const pwa: ProductWithAuction = {
        product,
        auctionWithHighestBid,
      }
      productWithAuctionList.push(pwa)
    }
  }

  return productWithAuctionList
}
