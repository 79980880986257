import type { AuctionWithBids } from '../../types'
import { getAuctionByTfProductId } from './get-auction-by-product-id'
import { getAuctionWithBids } from './get-auction-with-bids'

export async function getAuctionByTfProductCodeWithBids(
  tfProductCode: string
): Promise<AuctionWithBids | null> {
  const auctionResult = await getAuctionByTfProductId(tfProductCode)
  if (!auctionResult.error && auctionResult.data) {
    return getAuctionWithBids(auctionResult.data)
  }
  return null
}
