import { useQuery } from '@tanstack/react-query'
import type { UseQueryOptions } from '@tanstack/react-query'
import { getDiscordGuildById } from '@twlvxtwlv/supabase'
import type { DiscordGuilds } from '@twlvxtwlv/supabase'

type UseGuildOptions = UseQueryOptions<{
  data: DiscordGuilds | null
  error: unknown | null
}>

interface UseDiscordGuildVariables {
  guildId: string | null
}

export function getUseDiscordGuildOptions({ guildId }: UseDiscordGuildVariables): UseGuildOptions {
  return {
    queryKey: ['discordGuild', guildId],
    enabled: !!guildId, // Ensure the query runs only if guildId is provided
    queryFn: () => getDiscordGuildById(guildId!),
  }
}

export function useDiscordGuild({ guildId }: UseDiscordGuildVariables) {
  return useQuery(getUseDiscordGuildOptions({ guildId }))
}
