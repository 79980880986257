import { supabase } from '../client'
import type { TrackArtistInsert } from '../../types'

export async function addArtistToTrack(trackArtistInsert: TrackArtistInsert) {
  const { data, error, status } = await supabase
    .from('trackArtists')
    .insert(trackArtistInsert)
    .select()
    .single()
    .throwOnError()
  return { data, error, status }
}
