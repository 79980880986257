import { supabase } from '../client'
import type { PlaylistUpdate } from '../../types'

export async function updatePlaylist(playlistId: string, playlistUpdate: PlaylistUpdate) {
  const { data, error } = await supabase
    .from('playlists')
    .update(playlistUpdate)
    .match({ id: playlistId })
    .select()
    .single()
    .throwOnError()

  return { data, error }
}
