import { supabase } from '../client'

export async function getEncodingPage(limit = 1000) {
  const { data, error } = await supabase
    .from('encodings')
    .select('*')
    .order('createdAt', { ascending: false })
    .limit(limit + 1)

  return { data, error }
}
