import { toCmsMediaFileUrl } from './to-cms-media-file-url'

export function getBackgroundImageUrl(imageUrl: string | undefined) {
  let backgroundImageUrl = '/images/twelvextwelve.com-background-image.webp'
  if (imageUrl) {
    const cmsImageUrl = toCmsMediaFileUrl(imageUrl)
    if (cmsImageUrl) {
      backgroundImageUrl = cmsImageUrl
    }
  }
  return backgroundImageUrl
}
