import type { TfCustomerDiscordUser } from '../../types'
import { toCursorPage } from '../../utils'
import { supabase } from '../client'

export async function getDiscordUsersPage(cursor: string = new Date().toISOString(), limit = 1000) {
  const { data, error } = await supabase
    .from('tfCustomerDiscordUser')
    .select('*')
    .lt('createdAt', cursor)
    .order('createdAt', { ascending: false })
    .limit(limit + 1)

  let page = null
  if (!error && data !== null) {
    page = toCursorPage<TfCustomerDiscordUser>(data as TfCustomerDiscordUser[], limit)
  }

  return { page, error }
}
