import { supabase } from '../client'

export async function getEncodingById(encodingId: string) {
  const { data, error } = await supabase
    .from('encodings')
    .select(`*`)
    .match({ id: encodingId })
    .single()

  return { data, error }
}
