import type { GetOwnedAmountOfProductQueryVariables } from '@twlvxtwlv/tokenforge'
import { getCustomerOwnsProduct } from '@twlvxtwlv/tokenforge'
import { useQuery } from '@tanstack/react-query'

export function getCustomerOwnsProductOptions(
  variables: GetOwnedAmountOfProductQueryVariables,
  token: string | null
) {
  return {
    queryKey: ['customerOwnsProduct', { productId: variables.id }],
    queryFn: () => getCustomerOwnsProduct(variables, token!),
    enabled: !!token,
  }
}

export function useCustomerOwnsProduct(
  variables: GetOwnedAmountOfProductQueryVariables,
  token: string | null
) {
  const result = useQuery(getCustomerOwnsProductOptions(variables, token))
  const customerOwnsProduct = result.data ?? false

  return { customerOwnsProduct, ...result }
}
