import { supabase } from '../client'

export async function deleteEncoding(encodingId: string) {
  const { data, error } = await supabase
    .from('encodings')
    .delete()
    .match({ id: encodingId })
    .throwOnError()
  return { data, error }
}
