import { initDistinctId, setDistinctId } from '@twlvxtwlv/utils/src/cookie'
import { serverSideAlias } from './server-side-alias'

export async function handleAliasOnLogin(newDistinctId: string) {
  const oldDistinctId = initDistinctId()

  if (oldDistinctId) {
    const aliasResponse = await serverSideAlias({
      newId: newDistinctId,
      oldId: oldDistinctId,
    })

    if (aliasResponse.status === 200) {
      setDistinctId(newDistinctId)
    }
  }
}
