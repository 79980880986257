import { supabase } from '../client'
import type { TfCustomerDiscordUser } from '../../types'
import type { PostgrestError } from '@supabase/supabase-js'

export async function getPairedDiscordUser(customerUuid: string): Promise<{
  data: Awaited<TfCustomerDiscordUser> | null
  error: PostgrestError | null
}> {
  const { data, error } = await supabase
    .from('tfCustomerDiscordUser')
    .select('*')
    .match({ tfCustomerId: customerUuid })
    //.contains('guilds', [discordGuildId])
    .limit(1)
    .single()

  return { data, error }
}
