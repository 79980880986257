import { supabase } from '../client'
import type { TrackUpdate } from '../../types'

export async function updateTrack(trackId: string, trackUpdate: TrackUpdate) {
  const { data, error } = await supabase
    .from('tracks')
    .update(trackUpdate)
    .match({ id: trackId })
    .select()
    .single()
    .throwOnError()

  return { data, error }
}
