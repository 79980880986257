import { supabase } from '../client'
import type { TrackInsert } from '../../types'

export async function addTrack(trackInsert: TrackInsert) {
  const { data, error, status } = await supabase
    .from('tracks')
    .insert(trackInsert)
    .select()
    .single()
    .throwOnError()
  return { data, error, status }
}
