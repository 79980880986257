import { useEffect, useRef, useState } from 'react'
import { extractUuidFromUri } from '@twlvxtwlv/utils/src/routing'
import type { PairingResponse } from '@twlvxtwlv/types'

async function pairCustomerDiscordUser(
  customerIri: string,
  walletId: string | undefined,
  discordAccessToken: string
) {
  return await fetch('/api/discord/pair-customer-discord-user', {
    method: 'POST',
    body: JSON.stringify({
      tfCustomerUuid: extractUuidFromUri(customerIri),
      walletId: walletId,
      accessToken: discordAccessToken,
    }),
  })
}

export function usePairCustomerDiscordUser(
  customerIri: string,
  routerAsPath: string,
  walletId: string | undefined
) {
  const firstRenderRef = useRef(true)
  const [pairingResponse, setPairingResponse] = useState<PairingResponse | null>(null)

  useEffect(() => {
    if (firstRenderRef.current && process.env.NODE_ENV === 'development') {
      firstRenderRef.current = false
      return
    }

    let urlPath = routerAsPath
    const parts = routerAsPath.split('#')
    if (parts && parts.length > 1) {
      urlPath = parts[1]
    }
    const fragment = new URLSearchParams(urlPath)
    const discordAccessToken = fragment.get('access_token')
    if (!discordAccessToken) {
      console.error(
        `Will not pair customer with discord user because there is no discord access token`
      )
      return
    }

    pairCustomerDiscordUser(customerIri, walletId, discordAccessToken).then((res) =>
      setPairingResponse({ result: res })
    )
  }, [])

  return pairingResponse
}
