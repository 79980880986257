import type { ArtistWithProducts } from '@twlvxtwlv/tokenforge'
import { getProductsForRoadmap } from '@twlvxtwlv/tokenforge'
import type {
  CampaignEntity,
  ComponentCampaignCampaignRoadmap,
  ComponentCampaignCurrentNft,
} from '@twlvxtwlv/cms'
import { toCmsMediaFileUrl } from '@twlvxtwlv/cms'
import type { CurrentNftWithProduct } from '../types'
import { toCurrentNftsWithProducts, toRoadmapEntries } from '../products'
import type { RoadmapEntry } from '@twlvxtwlv/types'
import { toCampaignNavigationRecords } from './to-campaign-navigation-records'

export async function toCampaignSsrProps(
  artistsWithProducts: ArtistWithProducts[],
  campaignEntity: CampaignEntity,
  locale: string
) {
  const campaignAttributes = campaignEntity.attributes!
  const allProducts = artistsWithProducts.flatMap(
    (artistWithProducts) => artistWithProducts.products
  )

  // currentNftsWithProducts contains either campaignAttributes.CurrentNft or campaignAttributes.currentNfts
  let currentNftsWithProducts: CurrentNftWithProduct[] = []
  if (allProducts && campaignAttributes.CurrentNft?.nftId) {
    // CMS CurrentNft.nftId => 1 TF Product
    currentNftsWithProducts = toCurrentNftsWithProducts(
      [campaignAttributes.CurrentNft as ComponentCampaignCurrentNft],
      allProducts
    )
  } else if (
    allProducts &&
    campaignAttributes.currentNfts &&
    campaignAttributes.currentNfts.length > 0
  ) {
    // CMS currentNfts.nftIds => n TF Products
    currentNftsWithProducts = toCurrentNftsWithProducts(
      campaignAttributes.currentNfts as ComponentCampaignCurrentNft[],
      allProducts
    )
  }

  let roadmapEntries: RoadmapEntry[] = []
  if (campaignAttributes.Roadmap && campaignAttributes.Roadmap.length > 0) {
    const roadmapNftIds = campaignAttributes.Roadmap.map((roadmap) => roadmap?.nftId).filter(
      (nftId) => nftId
    ) as string[]

    const roadmapProducts = await getProductsForRoadmap(roadmapNftIds, allProducts ?? null, locale)
    roadmapEntries = toRoadmapEntries(
      campaignAttributes.Roadmap as ComponentCampaignCampaignRoadmap[],
      roadmapProducts
    )
  }

  const campaignNavigationRecords = toCampaignNavigationRecords(
    campaignEntity,
    currentNftsWithProducts,
    roadmapEntries
  )

  let backgroundImageUrl = toCmsMediaFileUrl(
    campaignEntity?.attributes?.Background?.data?.attributes?.url
  )
  if (!backgroundImageUrl) {
    backgroundImageUrl = '/images/twelvextwelve.com-background-image.webp'
  }

  return {
    allProducts,
    backgroundImageUrl,
    campaignNavigationRecords,
    currentNftsWithProducts,
    roadmapEntries,
  }
}
