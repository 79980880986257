import { supabase } from '../client'

export async function getBidById(bidId: number) {
  const { data, error } = await supabase
    .from('bids')
    .select('*')
    .match({ id: bidId })
    .limit(1)
    .single()

  return { data, error }
}
