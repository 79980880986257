import cmsGraphQlClient from '../client/graphql-client'
import gql from 'graphql-tag'
import type { GetCampaignByCampaignIdQueryVariables } from '../generated/graphql'

gql`
  query getCampaignByCampaignId(
    $id: String
    $locale: I18NLocaleCode
    $currentNftsPage: Int!
    $currentNftsPageSize: Int!
  ) {
    campaigns(
      publicationState: PREVIEW
      filters: { tokenForge: { campaignId: { eq: $id } } }
      locale: $locale
    ) {
      data {
        id
        attributes {
          ctaButton {
            enable
            buttonTitle
          }
          newsletterID
          tokenForge {
            id
            campaignId
            Artists {
              id
              TokenForge_UUID
            }
          }
          Background {
            data {
              id
              attributes {
                url
                alternativeText
              }
            }
          }
          Video {
            data {
              id
              attributes {
                url
                alternativeText
              }
            }
          }
          Audio {
            data {
              id
              attributes {
                url
              }
            }
          }
          CurrentNft {
            id
            nftId
            title
            type
            headline
            subHeadline
            contents {
              id
              headline
              description
              imageAlt
              imageUrl {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
          currentNfts(pagination: { page: $currentNftsPage, pageSize: $currentNftsPageSize }) {
            id
            nftId
            title
            type
            headline
            subHeadline
            contents {
              id
              headline
              description
              imageAlt
              imageUrl {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
          containedNfts {
            headline
            subHeadline
          }
          Roadmap {
            nftId
            headline
            subHeadline
            startAt
            startAtText
          }
          campaignContents {
            headline
            subHeadline
            image {
              data {
                attributes {
                  url
                  alternativeText
                }
              }
            }
            contents {
              headline
              description
              image {
                data {
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export function getCampaignByCampaignId(variables: GetCampaignByCampaignIdQueryVariables) {
  return cmsGraphQlClient.getCampaignByCampaignId(variables)
}
