import type { ClaimPage } from '@twlvxtwlv/cms'
import { getClaimsById } from '@twlvxtwlv/cms'
import { useQuery } from '@tanstack/react-query'

interface UseClaimVariables {
  claimId: string | null
  locale: string | null
}

export function getUseClaimOptions({ claimId, locale }: UseClaimVariables) {
  return {
    queryKey: ['claim', { claimId }],
    queryFn: () => getClaimsById({ locale, id: claimId }),
    enabled: !!claimId,
  }
}

export function useClaim(variables: UseClaimVariables) {
  const res = useQuery(getUseClaimOptions(variables))
  const claimAttributes = res?.data?.claimPages?.data?.[0]?.attributes as ClaimPage | undefined

  return { claimAttributes, ...res }
}
