import { supabase } from '../client'
import type { EncodingInsert } from '../../types'

export async function addEncoding(encodingInsert: EncodingInsert) {
  const { data, error, status } = await supabase
    .from('encodings')
    .insert(encodingInsert)
    .select()
    .single()

  return { data, error, status }
}
