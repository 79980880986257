import type { Address } from '@twlvxtwlv/tokenforge'
import { getCustomerAddress } from '@twlvxtwlv/tokenforge'
import { useQuery } from '@tanstack/react-query'

interface UseCustomerAddressVariables {
  iri: string | null
  accessToken: string
}

export function getUseCustomerAddressOptions({ iri, accessToken }: UseCustomerAddressVariables) {
  return {
    queryKey: ['address', { iri }],
    queryFn: () =>
      getCustomerAddress(
        {
          id: iri!,
        },
        accessToken
      ),
    enabled: !!iri,
  }
}

export function useCustomerAddress(variables: UseCustomerAddressVariables) {
  const res = useQuery(getUseCustomerAddressOptions(variables))
  const address = res?.data?.address as Address

  return { address, ...res }
}
