import { supabase } from '../client'

export async function getProductPreviewByProductId(tfProductId: string) {
  const { data, error } = await supabase
    .from('productPreviews')
    .select('*')
    .match({ tfProductId })
    .limit(1)
    .single()
  return { data, error }
}
