import { supabase } from '../client'
import type { PlaylistWithTracks } from '../../types'
import { toPlaylistWithTracks } from './to-playlist-with-tracks'

export async function getPlaylistById(playlistId: string) {
  const { data, error } = await supabase
    .from('playlists')
    .select(
      `
*,
artists:playlistArtists(artist:artists(*)),
tracks:playlistTracks(track:tracks(*, encodings(id), artists:trackArtists(artist:artists(*))))
  `
    )
    .match({ id: playlistId })
    .returns<PlaylistWithTracks[]>()
    .limit(1)
    .single()

  const mappedData = toPlaylistWithTracks(data)

  return { data: mappedData, error }
}
