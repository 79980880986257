import { supabase } from '../client'

export async function getDiscordGuilds() {
  const { data, error } = await supabase
    .from('discordGuilds')
    .select('*')

  if (error) {
    console.error("Error fetching guilds:", error);
    return null;
  }

  return data;
}
