import { supabase } from '../client'

export async function getBidsByAuctionIdPage(
  auctionId: number,
  cursor: string = new Date().toISOString(),
  limit = 10
) {
  const { data, error } = await supabase
    .from('bids')
    .select('*')
    .match({ auctionId: auctionId })
    .lt('createdAt', cursor)
    .order('priceInEuro', { ascending: false })
    .limit(limit + 1) // load one more item, to check hasNextPage

  let page = undefined
  if (data) {
    const hasMoreBids = data.length > limit
    page = {
      bids: hasMoreBids ? data.slice(0, -1) : data,
      hasMoreBids,
    }
  }

  return { page, error }
}
