import { supabase } from '../client'
import type { ArtistUpdate } from '../../types'

export async function updateArtist(artistId: string, artistUpdate: ArtistUpdate) {
  const { data, error } = await supabase
    .from('artists')
    .update(artistUpdate)
    .match({ id: artistId })
    .select()
    .single()
    .throwOnError()

  return { data, error }
}
