import { supabase } from '../client'

export async function getExternalGateByProductId(productId: string) {
  const { data, error } = await supabase
    .from('externalGate')
    .select('productId')
    .match({ productId })

  return { data, error }
}
