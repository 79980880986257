import type { Customer } from '@twlvxtwlv/tokenforge'
import { getCustomerWithJwtByIri } from '@twlvxtwlv/tokenforge'
import { useQuery } from '@tanstack/react-query'

interface UseCustomerWithJwtVariables {
  customerIri: string | null
  accessToken: string | null
}

export function getUseCustomerWithJwtOptions({
  customerIri,
  accessToken,
}: UseCustomerWithJwtVariables) {
  return {
    queryKey: ['customer', { customerIri }],
    queryFn: () => getCustomerWithJwtByIri(customerIri!, accessToken!),
    enabled: !!customerIri && !!accessToken,
  }
}

export function useCustomerWithJwt(variables: UseCustomerWithJwtVariables) {
  const res = useQuery(getUseCustomerWithJwtOptions(variables))

  const customer = res?.data?.getCustomer as Customer | undefined

  return { ...res, customer }
}
