import { supabase } from '../client'

export async function getAuctionByTfProductId(tfProductId: string) {
  const { data, error } = await supabase
    .from('auctions')
    .select('*')
    .match({ tfProductId: tfProductId })
    .limit(1)
    .single()
  return { data, error }
}
