import { supabase } from '../client'
import type { AuctionUpdate } from '../../types'

export async function updateAuction(auctionId: number, auctionUpdate: AuctionUpdate) {
  const { data, error } = await supabase
    .from('auctions')
    .update(auctionUpdate)
    .match({ id: auctionId })
    .select()
    .single()

  return { data, error }
}
