import { supabase } from '../client'

export async function getAuctionsPage(cursor: string = new Date().toISOString(), limit = 1000) {
  const { data, error } = await supabase
    .from('auctions')
    .select('*')
    .lt('createdAt', cursor)
    .order('createdAt', { ascending: false })
    .limit(limit + 1) // load one more item, to check hasNextPage

  // let page = null
  // if (!error && data !== null) {
  //   page = toCursorPage<Auction>(data as Auction[], limit)
  // }

  return { data, error }
}
