import { gql } from 'graphql-request'
import cmsGraphQlClient from '../client/graphql-client'
import type { GetEditorialsEqualsCategoryIdQueryVariables } from '../generated/graphql'

gql`
  query getEditorialsEqualsCategoryId(
    $editorialsPage: Int!
    $editorialsPageSize: Int!
    $locale: I18NLocaleCode!
    $categoryId: ID
    $publicationState: PublicationState
  ) {
    editorials(
      pagination: { page: $editorialsPage, pageSize: $editorialsPageSize }
      filters: { category: { id: { eq: $categoryId } } }
      sort: "id:desc"
      locale: $locale
      publicationState: $publicationState
    ) {
      meta {
        pagination {
          page
          pageCount
          pageSize
          total
        }
      }
      data {
        id
        attributes {
          title
          author
          slug
          createdAt
          publishedAt
          externalEntry
          mainImage {
            data {
              attributes {
                alternativeText
                mime
                name
                previewUrl
                url
                height
                width
              }
            }
          }
          thumbnail {
            data {
              attributes {
                alternativeText
                name
                mime
                height
                width
                url
              }
            }
          }
          category {
            data {
              attributes {
                name
                slug
              }
            }
          }
        }
      }
    }
  }
`

export function getEditorialsEqualsCategoryId(
  variables: GetEditorialsEqualsCategoryIdQueryVariables
) {
  if (variables.categoryId === null) {
    variables.categoryId = undefined
  }

  return cmsGraphQlClient.getEditorialsEqualsCategoryId(variables)
}
