import { supabase } from '../client'

export async function deleteArtist(artistId: string) {
  const { data, error } = await supabase
    .from('artists')
    .delete()
    .match({ id: artistId })
    .throwOnError()
  return { data, error }
}
