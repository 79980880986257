import { supabase } from '../client'
import type { PlaylistTrackInsert } from '../../types'

export async function addTrackToPlaylist(playlistTrackInsert: PlaylistTrackInsert) {
  const { data, error, status } = await supabase
    .from('playlistTracks')
    .insert(playlistTrackInsert)
    .select()
    .single()
    .throwOnError()
  return { data, error, status }
}
