import { supabase } from '../client'
import type { PreviewEncodingUpdate } from '../../types'

export async function updatePreviewEncoding(
  previewEncodingId: string,
  previewEncodingUpdate: PreviewEncodingUpdate
) {
  const { data, error } = await supabase
    .from('previewEncodings')
    .update(previewEncodingUpdate)
    .match({ id: previewEncodingId })
    .select()
    .single()

  return { data, error }
}
