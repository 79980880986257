import type { AuctionWithHighestBid, AuctionWithHighestBidQueryResult } from '../../types'

export function toAuctionsWithHighestBid(
  auctions: AuctionWithHighestBidQueryResult[]
): AuctionWithHighestBid[] {
  return auctions.map((auction) => {
    const formattedAuction: AuctionWithHighestBid = {
      ...auction,
      highestBidInEuros: auction?.bids[0]?.priceInEuro ?? 0,
    }
    return formattedAuction
  })
}
