import type { TfProductDiscordRole } from '../../types'
import { toCursorPage } from '../../utils'
import { supabase } from '../client'

export async function getDiscordRolesByGuild(
  guildId: string,
  cursor: string = new Date().toISOString(),
  limit = 25
) {
  const { data, error } = await supabase
    .from('tfProductDiscordRole')
    .select('*')
    .eq('guildId', guildId)           // Filter by guildId
    .lt('createdAt', cursor)
    .order('createdAt', { ascending: false })
    .limit(limit + 1)

  let page = null
  if (!error && data !== null) {
    page = toCursorPage<TfProductDiscordRole>(data as TfProductDiscordRole[], limit)
  }

  return { page, error }
}
