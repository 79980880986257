import type { UserProperties } from '../../user-properties'
import { apiClient } from '@twlvxtwlv/api'
import type { SetPropertiesRequestBodyType } from '../../api-types'

//Future improvement: change the parameters to be an inferred type from zod without Omit - for this we need to fix the type of userProperties in the zod schema
export function serverSideSetUserProperties(
  input: Omit<SetPropertiesRequestBodyType, 'userProperties'>,
  userProperties: UserProperties
) {
  return apiClient.post('/api/stats/properties', {
    ...input,
    userProperties,
  })
}
