import { supabase } from '../client'

export async function deleteTrack(trackId: string) {
  const { data, error } = await supabase
    .from('tracks')
    .delete()
    .match({ id: trackId })
    .throwOnError()
  return { data, error }
}
