import type { PostgrestError } from '@supabase/supabase-js'
import type { TfProductDiscordRole } from '../../types'
import { supabase } from '../client'

export async function getDiscordRolesByTfProductIds(tfProductIds: string[]): Promise<{
  data: Awaited<TfProductDiscordRole[]> | null
  error: PostgrestError | null
}> {
  const { data, error } = await supabase
    .from('tfProductDiscordRole')
    .select('*')
    .in('tfProductId', tfProductIds)

  return { data, error }
}
