import { getProductVariantById } from '@twlvxtwlv/tokenforge'
import { useQuery } from '@tanstack/react-query'

interface UseProductVariantVariables {
  productVariantId: string | null
}

export function getUseProductVariantOptions({ productVariantId }: UseProductVariantVariables) {
  return {
    queryKey: ['productVariant', { id: productVariantId }],
    queryFn: () => getProductVariantById({ id: productVariantId! }),
    enabled: !!productVariantId,
  }
}

export function useProductVariant(variables: UseProductVariantVariables) {
  const res = useQuery(getUseProductVariantOptions(variables))
  const productVariant = res?.data?.productVariant ?? null
  return { productVariant, ...res }
}
