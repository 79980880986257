import type { AuctionWithBids } from '../../types'
import { getAuctionById } from './get-auction-by-id'
import { getAuctionWithBids } from './get-auction-with-bids'

export async function getAuctionByAuctionIdWithBids(
  auctionId: number
): Promise<AuctionWithBids | null> {
  const auctionResult = await getAuctionById(auctionId)
  if (!auctionResult.error && auctionResult.data) {
    return getAuctionWithBids(auctionResult.data)
  }
  return null
}
