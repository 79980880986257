import { supabase } from '../client'

export async function getPlaylistsPage(cursor: string = new Date().toISOString(), limit = 1000) {
  const { data, error } = await supabase
    .from('playlists')
    .select('*')
    .lt('createdAt', cursor)
    .order('createdAt', { ascending: false })
    .limit(limit + 1)

  /*  let page = null
  if (!error && data !== null) {
    page = toCursorPage<Playlist>(data as Playlist[], limit)
  }*/

  return { data, error }
}
