import { supabase } from '../client'

export async function getDiscordRolesByTfProductId(tfProductId: string) {
  const { data, error } = await supabase
    .from('tfProductDiscordRole')
    .select('discordRoleId, guildId')
    .match({ tfProductId })

  return { data, error }
}
