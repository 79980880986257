import { gql } from 'graphql-request'
import cmsGraphQlClient from '../client/graphql-client'
import type { GetEditorialCategoriesQueryVariables } from '../generated/graphql'

gql`
  query getEditorialCategories($locale: I18NLocaleCode!) {
    editorialCategories(publicationState: LIVE, sort: "id:desc", locale: $locale) {
      data {
        id
        attributes {
          name
          slug
        }
      }
    }
  }
`

export function getEditorialCategories(variables: GetEditorialCategoriesQueryVariables) {
  return cmsGraphQlClient.getEditorialCategories(variables)
}
