import { supabase } from '../client'
import type { TfCustomerDiscordUserInsert } from '../../types'

// Assuming TfCustomerDiscordUserInsert is the type for the Insert operation
export async function addDiscordUser(
  discordUserInsert: TfCustomerDiscordUserInsert,
  guildId: string
) {
  // Initialize or append to the guilds array
  const guildsArray = discordUserInsert.guilds ? [...discordUserInsert.guilds, guildId] : [guildId]

  // Create an updated insert object including the new guilds array
  const updatedDiscordUserInsert = {
    ...discordUserInsert,
    guilds: guildsArray,
  }

  // Perform the database insert operation
  const { data, error, status } = await supabase
    .from('tfCustomerDiscordUser')
    .insert(updatedDiscordUserInsert)
    .select()
    .single()

  return { data, error, status }
}
