import type { ComponentHottestDropsHottesOfDrops } from '@twlvxtwlv/cms'
import type { MediaFile } from '@twlvxtwlv/tokenforge'
import { toPublicMediaFileUri } from '@twlvxtwlv/tokenforge'
import type { NftProductWithCreatorAvatar } from '@twlvxtwlv/types'

export function toProductWithCreatorAvatar(
  product?: ComponentHottestDropsHottesOfDrops,
  avatar?: MediaFile
): NftProductWithCreatorAvatar {
  return {
    avatar_alt: avatar?.fileName ?? '',
    avatar_src: toPublicMediaFileUri(avatar, 's-thumb') ?? '',
    bg_image: product?.gallery?.data?.[0]?.attributes?.url ?? '',
    link: product?.campaign_link ?? '',
    date: product?.date ?? '',
    headline: product?.headline ?? '',
    name: product?.name ?? '',
  }
}
