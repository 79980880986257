import { supabase } from '../client'

export async function getAdminById(adminId: string) {
  const { data, error } = await supabase
    .from('admins')
    .select('*')
    .match({ id: adminId })
    .limit(1)
    .single()
  return { data, error }
}
